:root {
  --color-neutral-lightest: 248, 250, 252; /* #F8FAFC */
  --color-neutral-lighter: 241, 245, 249; /* #F1F5F9 */
  --color-neutral-light: 226, 232, 240; /* #E2E8F0 */
  --color-neutral-brighter: 203, 213, 225; /* #CBD5E1 */
  --color-neutral-bright: 148, 163, 187; /* #94A3BB */
  --color-neutral: 100, 116, 136; /* #647488 */
  --color-neutral-dark: 71, 85, 105; /* #475569 */
  --color-neutral-darker: 51, 65, 85; /* #334155 */
  --color-neutral-darkest: 30, 41, 59; /* #1E293B */
  --color-neutral-more-dark: 15, 23, 42; /* #0F172A */
  --color-blue: 0, 174, 239; /* #00aeef */
  --color-blue-lightest: 204, 239, 252; /* #cceffc */
  --color-blue-lighter: 120, 207, 239; /* #78CFEF */
  --color-blue-light: 72, 194, 239; /* #48C2EF */
  --color-blue-dark: 1, 148, 203; /* #0194cb */
  --color-blue-powder: 189, 231, 228; /* #BDE7E4 */
  --color-blue-rackley: 103, 136, 162; /* #6788a2 */
  --color-blue-link: 0, 174, 239; /* #00aeef */
  --color-blue-link-hover: 1, 148, 203; /* #0194cb */
  --color-orange: 239, 65, 41; /* #ef4129 */
  --color-orange-lightest: 247, 215, 210; /* #f7d7d2 */
  --color-orange-lighter: 239, 149, 136; /* # */
  --color-orange-light: 239, 107, 88; /* # */
  --color-orange-dark: 203, 54, 33; /* #cb3621 */
  --color-orange-alert-warning: 247, 215, 210; /* #f7d7d2 */
  --color-yellow: 250, 204, 21; /* #FACC15 */
  --color-banana-mania: 255, 240, 181; /* #FFF0B5 */
  --color-cosmic-latte: 255, 250, 232; /* #FFFAE8 */
  --color-yellow-lightest: 255, 251, 235; /* #FFFBEB */
  --color-yellow-lighter: 253, 230, 138; /* #FDE68A */
  --color-yellow-light: 245, 158, 11; /* #F59E0B */
  --color-yellow-dark: 245, 158, 11; /* #F59E08 */
  --color-gray: 216, 216, 216; /* #d8d8d8 */
  --color-gray-alert-info: 237, 237, 237; /* #ededed */
  --color-gray-light: 102, 102, 102; /* #666666 */
  --color-gray-lighter: 153, 153, 153; /* #999999 */
  --color-gray-platinum: 222, 226, 230; /* #DEE2E6 */
  --color-gray-border: 209, 213, 219; /* #d1d5db */
  --color-gray-lightest: 230, 230, 230; /* #e6e6e6 */
  --color-gray-dark: 136, 136, 136; /* #888888 */
  --color-default: 50, 50, 50; /* #323232 */
  --color-gray-shade: 221, 221, 221; /* #dddddd */
  --color-gray-smoke: 247, 247, 247; /* #f7f7f7 */
  --color-gray-accent: 215, 218, 224; /* #d7d8e0 */
  --color-gray-blue: 193, 228, 241; /* #c1e4f1 */
  --color-gray-menu-separator: 151, 151, 151; /* #979797 */
  --color-green: 4, 120, 87; /* #047857 */
  --color-honey-dew: 240, 249, 237; /* #F0F9ED */
  --color-green-alert-succeed: 204, 252, 211; /* #ccfcd3 */
  --color-green-lightest: 236, 253, 245; /* #ECFDF5 */
  --color-green-lighter: 204, 252, 211; /* #ccfcd3 */
  --color-green-light: 16, 185, 129; /* #10B981 */
  --color-green-dark: 6, 78, 59; /* #064E3B */
  --color-red: 208, 2, 27; /* #d0021b */
  --color-red-lightest: 254, 242, 242; /* #FEF2F2 */
  --color-pale-pink: 247, 220, 215; /* #F7DCD7 */
  --color-linen: 251, 239, 236; /* #fbefec */
  --color-red-lighter: 239, 115, 115; /* #EF7373 */
  --color-red-alert-error: 240, 75, 52; /* #f04b34 */
  --color-red-bright: 239, 0, 0; /* #ef0000 */
  --color-red-link: 239, 65, 41; /* #ef4f29 */
  --color-red-link-hover: 203, 54, 33; /* #cb3621 */
  --color-red-dark: 127, 29, 29; /* #7F1D1D */
  --color-black: 50, 50, 50; /* #323232 */
  --color-black-link: 50, 50, 50; /* #323232 */
  --color-black-link-hover: 186, 186, 186; /* #bababa */
  --color-black-dark: 0, 0, 0; /* #000000 */
  --color-white: 255, 255, 255; /* #ffffff */
  --color-white-link: 255, 255, 255; /* #ffffff */
  --color-white-ghost: 249, 249, 249; /* #f9f9f9 */
  --color-white-link-hover: 186, 186, 186; /* #bababa */
  --color-default: 50, 50, 50; /* #323232 */
}
