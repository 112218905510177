* {
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-light;
  letter-spacing: $font-default-spacing;
  color: $text-black;
}

strong {
  font-family: $font-family-bold;
  font-weight: $font-weight-bold;
}

button {
  all: unset;
}
