$fonts-url-path: '~styleguide/styles/fonts/files';

$helvetica-neue-lt-ult-lt-path: '#{$fonts-url-path}/386A9D_0_0';
$helvetica-neue-lt-ult-lt-it-path: '#{$fonts-url-path}/386A9D_1_0';
$helvetica-neue-lt-th-path: '#{$fonts-url-path}/386A9D_2_0';
$helvetica-neue-lt-th-it-path: '#{$fonts-url-path}/386A9D_3_0';
$helvetica-neue-lt-lt-path: '#{$fonts-url-path}/386A9D_4_0';
$helvetica-neue-lt-lt-it-path: '#{$fonts-url-path}/386A9D_5_0';
$helvetica-neue-lt-roman-path: '#{$fonts-url-path}/386A9D_6_0';
$helvetica-neue-lt-it-path: '#{$fonts-url-path}/386A9D_7_0';
$helvetica-neue-lt-md-path: '#{$fonts-url-path}/386A9D_8_0';
$helvetica-neue-lt-md-it-path: '#{$fonts-url-path}/386A9D_9_0';
$helvetica-neue-lt-bd-path: '#{$fonts-url-path}/386A9D_A_0';
$helvetica-neue-lt-bd-it-path: '#{$fonts-url-path}/386A9D_B_0';
$helvetica-neue-lt-hv-path: '#{$fonts-url-path}/386A9D_C_0';
$helvetica-neue-lt-hv-it-path: '#{$fonts-url-path}/386A9D_D_0';
$helvetica-neue-lt-blk-path: '#{$fonts-url-path}/386A9D_E_0';
$helvetica-neue-lt-blk-it-path: '#{$fonts-url-path}/386A9D_F_0';

@font-face {
  font-family: 'HelveticaNeueLTPro-UltLt';
  font-weight: $font-weight-ultra-light;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-ult-lt-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-UltLtIt';
  font-weight: $font-weight-ultra-light;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-ult-lt-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Th';
  font-weight: $font-weight-thin;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-th-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-bd-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-ThIt';
  font-weight: $font-weight-thin;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-th-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Lt';
  font-weight: $font-weight-light;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-lt-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-LtIt';
  font-weight: $font-weight-light;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-lt-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-roman-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-It';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Md';
  font-weight: $font-weight-medium;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-md-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-MdIt';
  font-weight: $font-weight-medium;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-md-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-bd-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-BdIt';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-bd-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Hv';
  font-weight: $font-weight-heavy;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-hv-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-HvIt';
  font-weight: $font-weight-heavy;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-hv-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-HvIt';
  font-weight: $font-weight-heavy;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-hv-it-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Blk';
  font-weight: $font-weight-black;
  font-style: normal;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-blk-path}.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-BlkIt';
  font-weight: $font-weight-black;
  font-style: italic;
  font-display: swap;
  src: url('#{$helvetica-neue-lt-blk-it-path}.woff') format('woff');
}
