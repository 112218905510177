.heading-light-xl {
  @apply font-hvLite text-9xl font-light;
}

.heading-xl {
  @apply font-hvRoman text-9xl font-normal;
}

.heading-medium-xl {
  @apply font-hvMedium text-9xl font-medium;
}

.heading-bold-xl {
  @apply font-hvBold text-9xl font-bold;
}

.heading-heavy-xl {
  @apply font-hvHeavy text-9xl font-heavy;
}

.heading-light-lg {
  @apply font-hvLite text-6xl font-light;
}

.heading-lg {
  @apply font-hvRoman text-6xl font-normal;
}

.heading-medium-lg {
  @apply font-hvMedium text-6xl font-medium;
}

.heading-bold-lg {
  @apply font-hvBold text-6xl font-bold;
}

.heading-heavy-lg {
  @apply font-hvHeavy text-6xl font-heavy;
}

.heading-light-md {
  @apply font-hvLite text-5xl font-light;
}

.heading-md {
  @apply font-hvRoman text-5xl font-normal;
}

.heading-medium-md {
  @apply font-hvMedium text-5xl font-medium;
}

.heading-bold-md {
  @apply font-hvBold text-5xl font-bold;
}

.heading-heavy-md {
  @apply font-hvHeavy text-5xl font-heavy;
}

.heading-light-sm {
  @apply font-hvLite text-4xl font-light;
}

.heading-sm {
  @apply font-hvRoman text-4xl font-normal;
}

.heading-medium-sm {
  @apply font-hvMedium text-4xl font-medium;
}

.heading-bold-sm {
  @apply font-hvBold text-4xl font-bold;
}

.heading-heavy-sm {
  @apply font-hvHeavy text-4xl font-heavy;
}

.heading-light-xs {
  @apply font-hvLite text-2xl font-light;
}

.heading-xs {
  @apply font-hvRoman text-2xl font-normal;
}

.heading-medium-xs {
  @apply font-hvMedium text-2xl font-medium;
}

.heading-bold-xs {
  @apply font-hvBold text-2xl font-bold;
}

.heading-heavy-xs {
  @apply font-hvHeavy text-2xl font-heavy;
}

.sub-heading-light {
  @apply font-hvLite text-xl font-light leading-6;
}

.sub-heading {
  @apply font-hvRoman text-xl font-normal leading-6;
}

.sub-heading-medium {
  @apply font-hvMedium text-xl font-medium leading-6;
}

.sub-heading-bold {
  @apply font-hvBold text-xl font-bold leading-6;
}

.sub-heading-heavy {
  @apply font-hvHeavy text-xl font-heavy leading-6;
}

.paragraph-light-desktop {
  @apply font-hvLite text-base font-light leading-6;
}

.paragraph-desktop {
  @apply font-hvRoman text-base font-normal leading-6;
}

.paragraph-medium-desktop {
  @apply font-hvMedium text-base font-medium leading-6;
}

.paragraph-bold-desktop {
  @apply font-hvBold text-base font-bold leading-6;
}

.paragraph-heavy-desktop {
  @apply font-hvHeavy text-base font-heavy leading-6;
}

.paragraph-underline-desktop {
  @apply font-hvRoman text-base font-normal leading-6 underline;
}

.paragraph-light-mobile {
  @apply font-hvLite text-sm font-light leading-5;
}

.paragraph-mobile {
  @apply font-hvRoman text-sm font-normal leading-5;
}

.paragraph-medium-mobile {
  @apply font-hvMedium text-sm font-medium leading-5;
}

.paragraph-bold-mobile {
  @apply font-hvBold text-sm font-bold leading-5;
}

.paragraph-heavy-mobile {
  @apply font-hvHeavy text-sm font-heavy leading-5;
}

.paragraph-underline-mobile {
  @apply font-hvRoman text-sm font-normal leading-5 underline;
}

.caption {
  @apply font-hvRoman text-xs font-normal leading-4;
}

.caption-bold {
  @apply font-hvBold text-xs font-bold leading-4;
}

.caption-uppercase {
  @apply font-hvBold text-xs font-bold uppercase leading-3;
}

.caption-underline {
  @apply font-hvRoman text-xs font-normal leading-4 underline;
}
