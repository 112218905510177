.react-datepicker.calendar-container {
  @apply border border-solid border-blue shadow-lg;
  @apply absolute left-0 top-10 z-10;
}
.react-datepicker .picker-enabled {
  @apply text-default;
  @apply text-default transition duration-300 hover:bg-gray-50;
  @apply aria-disabled:bg-transparent aria-disabled:text-gray-300 aria-disabled:transition-none;
  @apply aria-selected:rounded aria-selected:border aria-selected:border-solid aria-selected:border-blue-500 aria-selected:bg-blue-50;
}

.react-datepicker .production-start-deadline {
  @apply text-blue transition duration-300 hover:bg-blue-50;
  @apply relative;
  @apply after:h-1.5 after:w-1.5 after:rounded-full after:content-[''];
  @apply after:absolute after:-bottom-1.5 after:left-[calc(50%_-_3px)] after:bg-blue;
}

.react-datepicker .ship-date {
  @apply text-yellow-700 transition duration-300 hover:bg-yellow-50;
  @apply relative;
  @apply after:h-1.5 after:w-1.5 after:rounded-full after:content-[''];
  @apply after:absolute after:-bottom-1.5 after:left-[calc(50%_-_3px)] after:bg-yellow-700;
}

.react-datepicker .delivery-date {
  @apply text-red-700 transition duration-300 hover:bg-red-50;
  @apply aria-disabled:text-red-700 aria-disabled:hover:bg-transparent;
  @apply relative;
  @apply after:h-1.5 after:w-1.5 after:rounded-full after:content-[''];
  @apply after:absolute after:-bottom-1.5 after:left-[calc(50%_-_3px)] after:bg-red-700;
}
