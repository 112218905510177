/*
Fix for bug in border styling
https://github.com/tailwindlabs/tailwindcss/issues/289
*/
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  border-spacing: 0;
  font: inherit;
  vertical-align: inherit;
}

* {
  border-width: 0;
  border-style: solid;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-hvLite font-normal leading-[1.375em] tracking-wide text-default;
}

@layer base {
  @import '../tailwind/base/colors.css';
}

@layer components {
  @import '../tailwind/components/typography.css';
  @import '../tailwind/components/reactdatepicker.css';
}
